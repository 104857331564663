import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    accessToken: localStorage.getItem('accessToken'),
    refreshToken: localStorage.getItem('refreshToken'),
    isValid: false, // Add isValid to track token validity
  });

  const saveTokens = (accessToken, refreshToken) => {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    setAuth({ accessToken, refreshToken, isValid: true });
  };

  const clearTokens = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    setAuth({ accessToken: null, refreshToken: null, isValid: false });
  };

  const validateToken = async () => {
    if (auth.accessToken) {
      try {
        const response = await axios.post(`${config.ServerURL}/api/oauth/validate`, { accessToken: auth.accessToken });
        setAuth((prevAuth) => ({ ...prevAuth, isValid: response.data.isValid }));
        if (!response.data.isValid) {
          clearTokens();
        }
      } catch (err) {
        console.error('Token validation failed:', err);
        // clearTokens();
      }
    }
  };

  const refreshTokens = async () => {
    if (auth.refreshToken) {
      try {
        const response = await axios.post(`${config.ServerURL}/api/oauth/refresh`, { refreshToken: auth.refreshToken });
        saveTokens(response.data.access_token, response.data.refresh_token);
      } catch (err) {
        console.error('Token refresh failed:', err);
        clearTokens();
      }
    }
  };

  // Set up axios interceptor
  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        if (auth.accessToken) {
          config.headers.Authorization = `Bearer ${auth.accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseInterceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          await refreshTokens();
          originalRequest.headers.Authorization = `Bearer ${auth.accessToken}`;
          return axios(originalRequest);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [auth.accessToken]);

  // Validate token on mount
  useEffect(() => {
    validateToken();
  }, []);

  // Set up token refresh interval
  useEffect(() => {
    const interval = setInterval(() => {
      refreshTokens();
    }, 900000); // 15 minutes

    return () => clearInterval(interval);
  }, [auth.refreshToken]);
  
  return (
    <AuthContext.Provider value={{ auth, saveTokens, clearTokens }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);