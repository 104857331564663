import React from 'react';
import PropTypes from 'prop-types';
import { Container, Box, Button, Typography, TextField, Paper } from '@mui/material';
import { LockOutlined as LockOutlinedIcon } from '@mui/icons-material';
import config from '../config';

const HubSpotLogin = ({ scopes }) => {
  const clientId = config.HubspotClientId;
  const redirectUri = config.HubspotCallbackUrl;
  const formattedScopes = scopes.join(' ');

  const authorizationUrl = `https://app.hubspot.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${formattedScopes}&response_type=code`;

  const handleLogin = () => {
    window.location.href = authorizationUrl;
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} sx={{ padding: 4, marginTop: 8 }}>
        {/* <Typography component="h1" variant="h4" sx={{textAlign: 'center'}}>
            LZB Hubspot Image Manager
        </Typography> */}
        <Box display="flex" flexDirection="column" alignItems="center">
          <LockOutlinedIcon color="primary" fontSize="large" />
          <Typography component="h2" variant="h5">
            Sign in with HubSpot
          </Typography>
          <Box mt={1}>
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleLogin}
              sx={{ mt: 3, mb: 2 }}
            >
              Login with HubSpot
            </Button>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

HubSpotLogin.propTypes = {
  scopes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default HubSpotLogin;