import React, { useEffect, useState } from 'react';
import { Drawer, Box, Typography, IconButton, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import UploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import SparkMD5 from 'spark-md5'; // Import SparkMD5
import config from '../../config';

const ItemTypes = {
  IMAGE: 'image',
};

const ProductFlyout = ({ product, open, onClose }) => {
  const [images, setImages] = useState([]);
  const [hubspot, setHubspot] = useState({});
  const [loading, setLoading] = useState(true);

  const isHighlighted = (path) => String(path).includes(`/hubfs/lzimages/${product.sku}`) || String(path).includes(`/images/${product.sku}`);
  const highlightedPaths = [
    hubspot.hs_images,
    hubspot.gallery_image_1,
    hubspot.gallery_image_2,
    hubspot.gallery_image_3,
    hubspot.gallery_image_4,
    hubspot.gallery_image_5,
    hubspot.gallery_image_6,
  ].filter(isHighlighted);

  useEffect(() => {
    if (open) {
      setLoading(true);
      axios.get(`${config.ServerURL}/api/product`, { params: { sku: product.sku } })
        .then(async response => {
          const uniqueImages = await filterDuplicateImages(response.data.images);
          setImages(uniqueImages);
          setHubspot(response.data.hubspot_product.properties);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching product images:', error);
          setLoading(false);
        });
    }
  }, [open, product.sku]);

  const filterDuplicateImages = async (images) => {
    const seenHashes = new Set();
    const uniqueImages = [];

    for (const image of images) {
      const hash = await getImageHash(image.path);
      if (!seenHashes.has(hash)) {
        seenHashes.add(hash);
        uniqueImages.push(image);
      }
    }

    return uniqueImages;
  };

  const getImageHash = (imagePath) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', `${config.ServerURL}/files/images/${product.sku}/${imagePath}`, true);
      xhr.responseType = 'arraybuffer';
      xhr.onload = function () {
        if (this.status === 200) {
          const spark = new SparkMD5.ArrayBuffer();
          spark.append(this.response);
          const hash = spark.end();
          resolve(hash);
        } else {
          console.log('image', imagePath)
          reject(new Error('Failed to load image'));
        }
      };
      xhr.onerror = function () {
        reject(new Error('Network error'));
      };
      xhr.send();
    });
  };

  const moveImage = (draggedIndex, toIndex) => {
    // console.log('draggedIndex', draggedIndex, images, images[draggedIndex.index]);
    const img = images[draggedIndex.index];

    const updatedHubspot = { ...hubspot };
    const galleryImages = [
      hubspot.hs_images,
      hubspot.gallery_image_1,
      hubspot.gallery_image_2,
      hubspot.gallery_image_3,
      hubspot.gallery_image_4,
      hubspot.gallery_image_5,
      hubspot.gallery_image_6,
    ];

    if (!img?.path) {
      console.log('ERROR WITH PATH', img);
      alert('That action failed, sorry. Try reloading the page and trying again.');
      return;
    }

    setHubspot(prevHubspot => {
      const updatedHubspot = { ...prevHubspot };
      const galleryImages = [
        prevHubspot.hs_images,
        prevHubspot.gallery_image_1,
        prevHubspot.gallery_image_2,
        prevHubspot.gallery_image_3,
        prevHubspot.gallery_image_4,
        prevHubspot.gallery_image_5,
        prevHubspot.gallery_image_6,
      ];
  
      galleryImages[toIndex] = `${config.ServerURL}/files/images/${product?.sku}/${img.path}`;
  
      updatedHubspot.hs_images       = galleryImages[0];
      updatedHubspot.gallery_image_1 = galleryImages[1];
      updatedHubspot.gallery_image_2 = galleryImages[2];
      updatedHubspot.gallery_image_3 = galleryImages[3];
      updatedHubspot.gallery_image_4 = galleryImages[4];
      updatedHubspot.gallery_image_5 = galleryImages[5];
      updatedHubspot.gallery_image_6 = galleryImages[6];
  
      return updatedHubspot;
    });
  
    // Call your API to update the image positions
    axios.post(`${config.ServerURL}/api/update-image-position`, {
      sku: product.sku,
      image: `/files/images/${product.sku}/${img.path}`,
      toIndex,
    }).catch(error => {
      console.error('Error updating image position:', error);
    });
  };

  const deleteImage = (index) => {
    if (window.confirm('Are you sure you want to detach this image?')) {
      const fieldNames = [
        'hs_images',
        'gallery_image_1',
        'gallery_image_2',
        'gallery_image_3',
        'gallery_image_4',
        'gallery_image_5',
        'gallery_image_6',
      ];
      const fieldName = fieldNames[index];

      axios.post(`${config.ServerURL}/api/delete-image`, {
        sku: product.sku,
        field_name: fieldName,
      }).then(() => {
        setHubspot(prevHubspot => {
          const updatedHubspot = { ...prevHubspot };
          updatedHubspot[fieldName] = null;
          return updatedHubspot;
        });
      }).catch(error => {
        console.error('Error deleting image:', error);
      });
    }
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box sx={{ width: '75vw', padding: 2 }}>
        <IconButton onClick={onClose} sx={{ float: 'right' }}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h5" gutterBottom>
          {product.name}
        </Typography>
        <Typography variant="body1">
          SKU: {product.sku}
        </Typography>
        <Typography>
          <strong>Product Link</strong>: <a href={`https://www.southtexasfurniture.com/product-listing/${product.sku}`} target="_blank">View Product</a>
        </Typography>
        <hr />
        <Typography variant="h6" color="textSecondary">
          Hubspot Product
        </Typography>
        <Typography variant="body1" gutterBottom>
          HS Object ID: {hubspot.hs_object_id}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Category: {hubspot.product_category}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Price {hubspot.price}
        </Typography>
        <Typography variant="h6" color="textSecondary">
          Hubspot Photos
        </Typography>
        <DndProvider backend={HTML5Backend}>
          {loading ? (
            <CircularProgress />
          ) : (
          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 2 }}>
            <DropZone key={'hs_images'} index={0} img={hubspot[`hs_images`]} moveImage={moveImage} deleteImage={deleteImage} isHighlighted={isHighlighted(hubspot[`hs_images`])} />
            {[1, 2, 3, 4, 5, 6].map((i) => (
              <DropZone key={i} index={i} img={hubspot[`gallery_image_${i}`]} moveImage={moveImage} deleteImage={deleteImage} isHighlighted={isHighlighted(hubspot[`gallery_image_${i}`])} />
            ))}
          </Box>
          )}
          <hr />
          <Typography variant="h6" color="textSecondary">
            LZB Images
          </Typography>
          {loading ? (
            <CircularProgress />
          ) : (
            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))', gap: 2 }}>
              {images.length ? images.map((image, index) => (
                <ImageComponent key={index} index={index} src={`${config.ServerURL}/files/images/${product?.sku}/${image?.path}`} isHighlighted={highlightedPaths.some(path => path.includes(`/${product?.sku}/${image?.path}`))} />
              )) : <Typography variant="body1">
              No Images Available
            </Typography>}
            </Box>
          )}
        </DndProvider>
      </Box>
    </Drawer>
  );
};

const DropZone = ({ index, img, moveImage, deleteImage, isHighlighted }) => {
  const [, drop] = useDrop(() => ({
    accept: ItemTypes.IMAGE,
    drop: (draggedItem) => {
      moveImage(draggedItem, index);
    },
  }));

  return (
    <Box ref={drop} sx={{ position: 'relative', maxWidth: '100%', width: 'auto', height: 225, objectFit: 'contain', border: isHighlighted ? '2px solid #9c27b0' : '1px dashed grey' }}>
      {img ? (
        <>
          <Box component="img" src={img} sx={{ width: '100%', height: '100%', objectFit: 'contain' }} />
          <IconButton
            onClick={() => deleteImage(index)}
            sx={{ position: 'absolute', top: 8, right: 8, backgroundColor: 'rgba(255, 255, 255, 0.7)' }}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ) : (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <UploadIcon />
        </Box>
      )}
    </Box>
  );
};

const ImageComponent = ({ index, src, isHighlighted }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.IMAGE,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [isVisible, setIsVisible] = useState(true);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const handleImageLoad = (event) => {
    const { naturalWidth, naturalHeight } = event.target;
    setDimensions({ width: naturalWidth, height: naturalHeight });
    if (naturalWidth < 400 || naturalHeight < 400) {
      setIsVisible(false);
    }
  };

  return (
    isVisible && (
      <Box
        ref={drag}
        sx={{
          position: 'relative',
          maxWidth: '100%',
          width: 'auto',
          height: 225,
          objectFit: 'contain',
          opacity: isDragging ? 0.5 : 1,
          cursor: 'move',
          border: isHighlighted ? '2px solid #9c27b0' : 'none',
        }}
      >
        <Box
          component="img"
          src={src}
          onLoad={handleImageLoad}
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            bottom: 8,
            right: 8,
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            color: 'white',
            padding: '2px 8px',
            borderRadius: '12px',
            fontSize: '12px',
          }}
        >
          {dimensions.width} x {dimensions.height}
        </Box>
      </Box>
    )
  );
};

export default ProductFlyout;