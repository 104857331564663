import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, CircularProgress, Box, TextField } from '@mui/material';
import ProductCard from './ProductCard';
import ProductFlyout from './ProductFlyout';
import config from '../../config';

const Products = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${config.ServerURL}/api/products`);
        setProducts(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filterProducts = (products, searchTerm) => {
    if (!searchTerm) return products;

    const terms = searchTerm.split(' ');
    if (terms.length === 1) {
      return products.filter(product =>
        product.sku.includes(searchTerm) || product.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else {
      return products.filter(product =>
        terms.every(term => product.name.toLowerCase().includes(term.toLowerCase()))
      );
    }
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
  };

  const handleCloseFlyout = () => {
    setSelectedProduct(null);
  };

  const filteredProducts = filterProducts(products, searchTerm);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <TextField
        label="Search Products"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={handleSearch}
        sx={{marginBottom: 5}}
      />
      <Grid container spacing={3}>
        {filteredProducts.map((product) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={product.id}>
            <ProductCard product={product} onClick={() => handleProductClick(product)} />
          </Grid>
        ))}
      </Grid>
      {selectedProduct && (
        <ProductFlyout
          product={selectedProduct}
          open={Boolean(selectedProduct)}
          onClose={handleCloseFlyout}
        />
      )}
    </Box>
  );
};

export default Products;