import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HubSpotLogin from './auth/HubspotLogin';
import OAuthCallbackComponent from './auth/OAuthCallbackComponent';
import PrivateRoute from './auth/PrivateRoute';
import { useAuth } from './auth/AuthContext';
import Layout from './template/Layout';
import Products from './pages/products/Products';

const App = () => {
  const { auth } = useAuth();
  const scopes = ['crm.objects.contacts.read', 'crm.objects.contacts.write', 'e-commerce', 'files', 'oauth'];
  return (
    <Router>
      <Routes>
        <Route path="/oauth/callback" element={<OAuthCallbackComponent />} />
        <Route path="/" element={auth.isValid ? <Navigate to="/app/products" /> : <HubSpotLogin scopes={scopes} />} />
        <Route path="/app" element={<PrivateRoute><Layout /></PrivateRoute>}>
          <Route path="/app/products" element={<Products />} />
        </Route>
      </Routes>
    </Router>
  )
};

export default App;