import React from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import config from '../../config';

const ProductCard = ({ product, ...rest }) => {
  return (
    <Card {...rest} sx={{cursor: 'pointer'}}>
      <CardMedia
        component="img"
        height="140"
        image={product.image ? `${config.ServerURL}/files/images/${product.sku}/${product.image}` : `${config.ServerURL}/files/lzb.svg`}
        alt={product.name}
        sx={{objectFit: "contain"}}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {product.name}
        </Typography>
        <Typography gutterBottom variant="h6" component="div" color='secondary'>
          {product.sku}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ProductCard;