import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from './AuthContext';
import config from '../config';

const OAuthCallbackComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { saveTokens, auth } = useAuth();
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchToken = async (code) => {
      try {
        const response = await axios.post(`${config.ServerURL}/api/oauth/token`, { code });
        const { access_token, refresh_token } = response.data;
        saveTokens(access_token, refresh_token);
      } catch (err) {
        setError(err.response ? err.response.data : err.message);
        console.error('Token exchange failed:', err.response ? err.response.data : err.message);
      }
    };

    const code = new URLSearchParams(location.search).get('code');
    if (auth.isValid) {
      navigate('/'); // Redirect to home if auth is already valid
    } else if (code) {
      fetchToken(code);
    } else {
      setError('Authorization code is missing');
    }
  }, [location, saveTokens]);

  if (error) {
    return <div>Error: {JSON.stringify(error)}</div>;
  }

  return <div>Loading...</div>;
};

export default OAuthCallbackComponent;